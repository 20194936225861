
.position {
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-size: contain;

    span {
        color: #fff;
    }
}
