
@import 'scss/global/_variables';

.feature-details-wrapper {
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr) 44px;

    .info {
        margin-left: 22px;

        .feature-header {
            padding-right: none !important;
            font-family: Avenir;
            font-size: 16px !important;
            font-weight: 500 !important;
            color: #505152 !important;
        }
    }

    .arrow {
        display: inline-block;
        height: 44px;
        width: 44px;
        background: url(#{$distImagesDir}right-arrow.svg) center no-repeat;
        background-size: 14px;
        align-self: center;
        justify-self: center;

        &.layer-expanded {
            background: url(#{$distImagesDir}down-arrow.svg) center no-repeat;
        }
    }
}
